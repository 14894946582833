/* eslint-disable  */

export default {
  'About us': 'Om oss',
  Account: 'Account',
  'Add new address': 'Lägg till ny adress',
  'Add to compare': 'Add to compare',
  'Add to Wishlist': 'Add to Wishlist',
  'Additional Information': 'Additional Information',
  'All Orders': 'Alla beställningar',
  'Allow order notifications': 'Allow order notifications',
  Apply: 'Aktivera',
  'Applied Coupon': 'Rabattkod',
  'Attention!': 'Attention!',
  'Back to home': 'Tillbaka till hemmet',
  'Back to homepage': 'Tillbaka till hemmet',
  Billing: 'Betalningsmetod',
  'Billing address': 'Fakturaadress',
  Brand: 'Brand',
  Cancel: 'Cancel',
  Cart: 'Cart',
  Categories: 'Categories',
  Change: 'Uppdatera',
  'Change password your account': 'Om du vill ändra lösenordet för att komma åt ditt konto anger du följande information',
  'Clear all': 'Clear all',
  Color: 'Color',
  'Commercial information': 'and agree to receive personalized commercial information from Brand name by email',
  'Contact details updated': 'Keep your addresses and contact details updated.',
  'Contact us': 'Contact us',
  'Continue to billing': 'Fortsätt',
  'Continue to payment': 'Fortsätt',
  'Continue to shipping': 'Continue to shipping',
  'Cookies Policy': 'Om Cookies',
  'Create an account': 'Skapa konto',
  'Customer service': 'Kundtjänst',
  Date: 'Datum',
  'Default Billing Address': 'Standardfaktureringsadress',
  'Default Shipping Address': 'Default Shipping Address',
  Delete: 'Radera',
  Departments: 'Departments',
  Description: 'Description',
  'Details and status orders': 'Här visas dina beställningar.',
  Discount: 'Discount',
  Done: 'Done',
  Download: 'Download',
  'Download all': 'Download all',
  Edit: 'Edit',
  'Email address': 'Epost',
  Empty: 'Det verkar som att du inte har lagt till några blodanalyser i din varukorg.',
  'Empty bag': 'Empty bag',
  'Enjoy your free account': 'Enjoy these perks with your free account!',
  'Enter promo code': 'Kupongkod',
  Feedback: 'Your feedback is important to us. Let us know what we could improve.',
  'Feel free to edit': 'Redigera dina uppgifter nedan så att ditt konto alltid är uppdaterat',
  Filters: 'Filters',
  'Find out more': 'Find out more',
  'First Name': 'Förnamn',
  'Forgot Password': 'If you can’t remember your password, you can reset it.',
  'Forgot Password Modal Email': 'E-post som du använder för att logga in:',
  forgotPasswordConfirmation:
    'Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.',
  'Forgotten password?': 'Glömt lösenordet?',
  'Go back': 'Go back',
  'Go back shopping': 'Tillbaka till shoppingen!',
  'Go back to shop': 'Go back to shop',
  'Go to checkout': 'Gå till kassan',
  'Go to cart': 'Gå till varukorgen',
  Guarantee: 'Guarantee',
  Help: 'Help',
  'Help & FAQs': 'Help & FAQs',
  hide: 'hide',
  Home: 'Hem',
  'I agree to': 'I agree to',
  'I confirm subscription': 'I confirm subscription',
  'I want to create an account': 'I want to create an account',
  'Info after order':
    'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.',
  Instruction1: 'Take care of me',
  Instruction2: 'Just here for the care instructions?',
  Instruction3: 'Yeah, we thought so',
  'It was not possible to request a new password, please check the entered email address.':
    'It was not possible to request a new password, please check the entered email address.',
  Item: 'Item',
  Items: 'Items',
  Kidswear: 'Kidswear',
  'Last Name': 'Efternamn',
  'Let’s start now – we’ll help you': 'Let’s start now – we’ll help you.',
  'Log into your account': 'Log into your account',
  Login: 'Logga in',
  'login in to your account': 'login in to your account',
  'Looks like you haven’t added any items to the Wishlist.': 'Det verkar som att du inte har lagt till några blodanalyser på din önskelista.',
  'Make an order': 'Make an order',
  'Manage addresses': 'Hantera alla adresser du vill ha. På så sätt behöver du inte ange adressen manuellt vid varje beställning.',
  'Manage billing addresses':
    'Manage all the billing addresses you want (work place, home address...) This way you won"t have to enter the billing address manually with each order.',
  'Manage shipping addresses':
    'Manage all the shipping addresses you want (work place, home address...) This way you won"t have to enter the shipping address manually with each order.',
  'Match it with': 'Match it with',
  'Men fashion': 'Men fashion',
  Menu: 'Menu',
  'My billing and shipping address are the same': 'My billing and shipping address are the same',
  'My Cart': 'Varukorg',
  'No account': 'Inget konto?',
  or: 'or',
  'or fill the details below': 'or fill the details below',
  'Order ID': 'Order ID',
  'Order information': 'Beställningsinformation',
  'Order No.': 'Ordernummer',
  'Order summary': 'Varukorg',
  'Other products you might like': 'Other products you might like',
  'Out of stock': 'Out of stock',
  Password: 'Lösenord',
  'Password Changed': 'Lösenordet har ändrats. Du kan nu gå tillbaka till startsidan och logga in.',
  'Pay for order': 'Pay for order',
  Payment: 'Betalning',
  'Payment & Delivery': 'Payment & Delivery',
  'Payment Method': 'Betalningsmetod',
  'Payment Methods': 'Payment Methods',
  'Personal details': 'Personlig information',
  'Please type your current password to change your email address.': 'Please type your current password to change your email address.',
  Price: 'Pris',
  Privacy: 'Privacy',
  'Privacy Policy': 'Privacy Policy',
  Product: 'Produkt',
  'Product suggestions': 'Product suggestions',
  Products: 'Produkter',
  'Products found': 'Products found',
  'Purchase terms': 'Purchase terms',
  'Quality in the details': 'Quality in the details',
  Quantity: 'Kvantitet',
  'Read all reviews': 'Read all reviews',
  'Read and understand': 'I have read and understand the',
  'Read reviews': 'Read reviews',
  Register: 'Register',
  'Register today': 'Register today',
  Remove: 'Ta bort',
  'Remove Address': 'Remove Address',
  'Remove from Wishlist': 'Remove from Wishlist',
  'Repeat Password': 'Upprepa lösenord',
  'Reset Password': 'Återställ lösenord',
  'Review my order': 'Review my order',
  'Same as shipping address': 'Same as shipping address',
  'Save changes': 'Save changes',
  'Save for later': 'Save for later',
  'Save Password': 'Spara lösenord',
  Search: 'Sök',
  'Search for items': 'Search for items',
  'Search results': 'Search results',
  'Sections that interest you': 'Sections that interest you',
  'See all results': 'See all results',
  'See more': 'See more',
  'Select payment method': 'Select payment method',
  'Select shipping method': 'Select shipping method',
  'Send my feedback': 'Send my feedback',
  'Set up newsletter':
    'Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.',
  'Share your look': 'Share your look',
  Shipping: 'Shipping',
  'Shipping address': 'Shipping address',
  'Shipping details': 'Shipping details',
  'Shipping method': 'Shipping method',
  Show: 'Show',
  'show more': 'show more',
  'Show on page': 'Show on page',
  'Sign in': 'Sign in',
  'Size guide': 'Size guide',
  'Sort by': 'Sort by',
  'Sort: Default': 'Default',
  'Sort: Name A-Z': 'Name A-Z',
  'Sort: Name Z-A': 'Name Z-A',
  'Sort: Price from high to low': 'Price from high to low',
  'Sort: Price from low to high': 'Price from low to high',
  'Start shopping': 'Start shopping',
  Status: 'Status',
  Subscribe: 'Subscribe',
  'Subscribe to newsletter': 'Subscribe to newsletter',
  subscribeToNewsletterModalContent:
    'After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.',
  Subtotal: 'Subtotal',
  'Subtotal price': 'Subtotal price',
  'Successful placed order': 'Vi har skickat ett bekräftelsemail till dig med all information som du behöver veta inför ditt test.',
  'Terms and conditions': 'Villkor',
  'Thank you': 'Thank you',
  'Thank You Inbox': 'If the message is not arriving in your inbox, try another email address you might’ve used to register.',
  Total: 'Total',
  'Order Total': 'Totalt',
  'Total items': 'Antal produkter',
  'Total price': 'Total price',
  'Update password': 'Update password',
  'Update personal data': 'Uppdatera personuppgifter',
  'Use your personal data':
    'På Blodkollen lägger vi stor vikt vid integritetsfrågor och är engagerade i att skydda våra användares personuppgifter. Läs mer om hur vi tar hand om och använder dina',
  'User Account': 'User Account',
  View: 'View',
  'View details': 'Se detaljer',
  'Vue Storefront Next': 'Vue Storefront Next',
  'Who we are': 'Who we are',
  Wishlist: 'Mina favoriter',
  'Women fashion': 'Women fashion',
  'You added {product} to your shopping cart.': 'You added {product} to your shopping cart.',
  'You can unsubscribe at any time': 'You can unsubscribe at any time',
  'You currently have no orders': 'You currently have no orders',
  'You haven’t searched for items yet': 'You haven’t searched for items yet.',
  'Your bag is empty': 'Your bag is empty',
  'Your current email address is': 'Your current email address is',
  'Your email address': 'Your email address',
  'The email field must be a valid email': 'The email field must be a valid email',
  'You have submitted no reviews': 'You have submitted no reviews',
  'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.':
    'Kontoinloggningen var felaktig eller så har ditt konto inaktiverats tillfälligt. Vänta och försök igen senare.',
  'A customer with the same email address already exists in an associated website.':
    'A customer with the same email address already exists in an associated website.',
  'Invalid email': 'Ogiltig epost',
  'Colorful summer dresses are already in store': 'Colorful summer dresses are already in store',
  'Learn more': 'Learn more',
  Dresses: 'Dresses',
  'Cocktail & Party': 'Cocktail & Party',
  'Linen Dresses': 'Linen Dresses',
  'T-Shirts': 'T-Shirts',
  'The office life': 'The office life',
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.":
    "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.",
  'Shop now': 'Shop now',
  'The Office Life': 'The Office Life',
  'Summer Sandals': 'Summer Sandals',
  'Eco Sandals': 'Eco Sandals',
  'Subscribe to Newsletters': 'Subscribe to Newsletters',
  'Be aware of upcoming sales and events. Receive gifts and special offers!':
    'Be aware of upcoming sales and events. Receive gifts and special offers',
  'Fashion to take away': 'Fashion to take away',
  'Download our application to your mobile': 'Download our application to your mobile',
  'Share Your Look': 'Share Your Look',
  'My Account': 'Min journal',
  'My profile': 'Kontouppgifter',
  'Personal Details': 'Personlig information',
  'Addresses details': 'Adressinfo',
  'My newsletter': 'My newsletter',
  'Log out': 'Logga ut',
  'My reviews': 'My reviews',
  'Order history': 'Mina beställningar',
  'Order details': 'Beställningar',
  'My wishlist': 'Mina favoriter',
  'Password change': 'Uppdatera lösenord',
  'Personal data': 'Personuppgifter',
  'Your e-mail': 'Epost',
  'Current Password': 'Nuvarande lösenord',
  'You are not authorized, please log in.': 'Du har blivit utloggad. Vänligen logga in igen.',
  'Go To Product': 'Go To Product',
  'Change to list view': 'Change to list view',
  'Change to grid view': 'Change to grid view',
  Returns: 'Returns',
  'My orders': 'Mina beställningar',
  'Add the address': 'Add the address',
  'Set as default shipping': 'Set as default shipping',
  'Set as default billing': 'Ställ in som standardfakturering',
  'House/Apartment number': 'Husnummer',
  'Street Name': 'Gatuadress',
  City: 'Stad',
  'State/Province': 'Län',
  'Zip-code': 'Postnummer',
  Country: 'Land',
  'Phone number': 'Telefonnummer',
  'Please select a country first': 'Please select a country first',
  'This field is required': 'Detta fält är obligatoriskt',
  'The field should have at least 2 characters': 'Fältet ska ha minst 2 tecken',
  'The field should have at least 4 characters': 'Fältet ska ha minst 4 tecken',
  'The field should have at least 8 characters': 'Fältet ska ha minst 8 tecken',
  'New Password': 'Nytt lösenord',
  'New Products': 'New Products',
  'There are no shipping methods available for this country. We are sorry, please try with different country.':
    'There are no shipping methods available for this country. We are sorry, please try with different country.',
  'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.':
    'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.',
  'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.':
    'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.',
  "We can't find products matching the selection.": "We can't find products matching the selection.",
  'Page not found': 'Page not found',
  'Back to Home page': 'Tillbaka till startsidan',
  'An error occurred': 'An error occurred',
  AllProductsFromCategory: 'All {categoryName}',
  'Show more': 'Show more',
  'Show less': 'Show less',
  Yes: 'Yes',
  No: 'No',
  'Apply filters': 'Apply filters',
  "The coupon code isn't valid. Verify the code and try again.": 'Kupongkoden är inte giltig. Verifiera koden och försök igen.',
  From: 'From',
  To: 'To',
  'Your customization': 'Your customization',
  "Passwords don't match": " Passwords don't match",
  'The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)':
    'Lösenordet måste vara minst 8 tecken långt och måste innehålla minst: 1 stor eller liten bokstav, 1 siffra eller ett specialtecken (t.ex. , . _ & ? etc)',
  'Show all products': 'Show all products',
  'Select previously saved address': 'Select previously saved address',
  'Enter different address': 'Enter different address',
  'You must confirm your account. Please check your email for the confirmation link.':
    'You must confirm your account. Please check your email for the confirmation link.',
  'Change Store': 'Change Store',
  'Choose Currency': 'Choose Currency',
  'Add a review': 'Add a review',
  'Add to cart': 'Lägg i varukorgen',
  Title: 'Title',
  Name: 'Namn',
  Review: 'Review',
  'Add review': 'Add review',
  'Are you sure you would like to remove this item from the shopping cart?':
    'Are you sure you would like to remove this item from the shopping cart?',
  'Your cart is empty': 'Din varukorg är tom',
  'Are you sure?': 'Are you sure?',
  '{0} has been successfully removed from your cart': '{0} har tagits bort från din kundvagn',
  Amount: 'Belopp',
  'Thank you for your order!': 'Tack för din beställning!',
  'Your Purchase': 'Your Purchase',
  'Primary contacts for any questions': 'Primary contacts for any questions',
  'Your Account': 'Your account',
  'What can we improve': 'What can we improve',
  'The user password was changed successfully updated!': 'The user password was changed successfully updated!',
  'The user account data was successfully updated!': 'The user account data was successfully updated!',
  'You submitted your review for moderation.': 'You submitted your review for moderation.',
  'Starting at': 'Starting at',
  'Blodkollen Sweden AB': 'Blodkollen Sverige AB',
  'Opening hours': 'Öppettider',
  'Monday - Thursday 08.15 - 14.00': 'Måndag - Torsdag 08.15 - 14.00',
  'Friday: 08.15 - 13.00': 'Fredag: 08.15 - 13.00',
  'Lunch: 11.30 - 12.30':'Lunch: 11.30 - 12.30',
  'General questions?': 'Generella frågor?',
  'Where you can find us': 'Här finns vi',
  'Fear of needles': 'Stickrädsla',
  'The doctor answers': 'Doktorn svarar',
  Current: 'Aktuellt',
  Email: 'Epost',
  Chat: 'Chatt',
  Tel: 'Tel',
  'Laboratory staff': 'Laboratoriepersonal',
  'About Blodkollen': 'Om Blodkollen',
  'Our blood tests': 'Våra blodanalyser',
  'Articles': 'Artiklar',
  'All': 'Alla',
  GDPR: 'GDPR',
  'Terms of purchase': 'Köpvillkor',
  'How does your body really feel on the inside?': 'Hur mår din kropp på insidan egentligen?',
  'We want it to be easy to find out how we actually feel on the inside through a health check.':
    'Vi vill att det ska vara enkelt att ta reda på hur vi faktiskt mår på insidan genom en hälsokoll.',
  'Scroll down': 'Scrolla ner',
  'Select blood analysis package': 'Välj blodanalyspaket',
  'Choose an analysis package that suits you and go to checkout.': 'Väl ett analyspaket som passar just dig och gå till kassan.',
  'Book a lab near you': 'Boka ett lab nära dig',
  'When you buy your analysis package, you choose a lab that is close to you.': 'När du köpt ditt analyspaket väljer du ett lab som ligger nära dig.',
  'Get your test results': 'Få dina provsvar',
  'You will receive an email when your test answers are available to download in my journal.':
    'Du får ett mail när dina provsvar finns att hämta i min journal.',
  'Analysis packages': 'Blodprov',
  'Show more analyses': 'Visa fler blodprov',
  'This is how it works': 'Så fungerar det',
  'Our partners': 'Våra partners',
  Expand: 'Expandera',
  'In case of any deviations refer to to your doctor.': 'Vid eventuella avvikelser hänvisar vi dig till din läkare.',
  'My account details': 'Mina kontouppgifter',
  'Shopping cart': 'Varukorg',
  'See analyses': 'Se analyser',
  'What do you want to analyze': 'Vad vill du analysera',
  'Before you take the test': 'Innan du tar provet',
  'Important things to do before your test': 'Viktigt inför din provtagning',
  Medication: 'Medicinering',
  'Food and alcohol': 'Mat och alkohol',
  'Bank ID': 'Bank ID',
  'Login with Bank ID': 'Logga in med Bankid',
  'Person number': 'Personnr',
  'Bankid on another device': 'Bankid på annan enhet',
  'Login with password': 'Logga in med lösenord',
  'Read more about BankID and where you can get it.': 'Läs mer om BankID och var du kan skaffa det.',
  'Analyses List': 'Analyslista',
  Checkout: 'Kassa',
  'Already have an account?': 'Har du redan ett konto?',
  Signup: 'Registrera dig',
  'Confirm password': 'Bekräfta lösenord',
  'Forgot Password Short': 'Glömt lösenord',
  'Email address / Personal number': 'Epost / Personnr',
  'Remove from cart': 'Ta bort från kundvagnen',
  'Your information': 'Dina uppgifter',
  'The blood test is for me': 'Blodprovet är till mig',
  'The blood test is for my child': 'Blodprovet är till mitt barn',
  'The blood sample is for someone else': 'Blodprovet är till någon annan',
  'Information about the child': 'Uppgifter om barnet',
  'Information about the sample taker': 'Uppgifter om provtagaren',
  "I certify that I am the child's guardian": 'Jag intygar att jag är barnets vårdnadshavare',
  'Confirm personal number': 'Upprepa personnnummer',
  'Search among all clinics': 'Sök bland alla mottagningar',
  'Optional medical history': 'Valfri anamnes',
  'Why are you taking the tests? What medications are you taking?': 'Varför tar du testerna? Vilka mediciner tar du?',
  'Choose clinic': 'Välj plats för provtagning',
  'The field should have at least 5 characters': 'Fältet ska ha minst 5 tecken',
  'The field should have at least 6 characters': 'Fältet ska ha minst 6 tecken',
  'The field should have at most 5 characters': 'Fältet ska ha högst 5 tecken',
  'The field should have at least 12 characters': 'Fältet ska ha minst 12 tecken',
  'The field should have at most 12 characters': 'Fältet ska ha högst 12 tecken',
  "You must confirm that you are the child's legal guardian": 'Du måste bekräfta att du är barnets lagliga vårdnadshavare',
  'Personal number confirmation does not match': 'Bekräftelse av personnummer stämmer inte överens',
  'Create an account on the store': 'Skapa ett konto i butiken',
  'Login on the store': 'Logga in på butiken',
  Reports: 'Rapporter',
  'View report': 'Visa rapport',
  Analyzed: 'Analyseras',
  Read: 'Klar',
  'Detailed analysis': 'Detaljerad analys',
  Analysis: 'Analys',
  Result: 'Resultat',
  Reference: 'Referens',
  Unit: 'Enhet',
  'Facts about': 'Fakta om',
  'its effect on the body and what affects values': 'dess effekt på kroppen vad som påverkar värden',
  'Read more about': 'Läs om',
  'Read about normal level': 'Läs om normal nivå',
  'Read about low level': 'Läs om låg nivå',
  'Read about high level': 'Läs om hög nivå',
  'Personal data policy': 'Personuppgiftspolicy',
  'Our team': 'Vårt team',
  'My reports': 'Mina rapporter',
  Customer: 'Kund',
  Open: 'Öppen',
  'Your summary': 'Din sammanfattning',
  Print: 'Skriv ut',
  'Download PDF': 'Ladda ner PDF',
  'Download Order receipt': 'Ladda ner orderkvitto',
  'View Order': 'Visa order',
  'Analysis results': 'Analysresultat',
  'Your wishlist is empty': 'Din önskelista är tom',
  'All analyses': 'Våra blodprover',
  'Compare previous results': 'Jämför tidigare resultat',
  'Your result': 'Ditt resultat',
  'Reference value': 'Referens värde',
  'Login successful': 'Inloggning lyckades',
  'Account created': 'Konto skapat',
  'Start the BankID app and press the Scan QR button. Then scan the QR code below.':
    'Starta BankID-appen och tryck på Skanna QR-knappen. Skanna sedan QR-koden nedan.',
  'Added to cart': 'Tillagd i kundvagnen',
  'If you have any questions, please contact our customer service.': 'Har du några frågor vänligen kontakta vår kundtjänst.',
  'Office/Address': 'Mottagning/Adress',
  'My Orders page': 'Mina beställningar:',
  'Payment date': 'Betalningsdatum',
  Pending: 'Väntar',
  Completed: 'Avslutad',
  Canceled: 'Avbruten',
  'Blood Tests': 'Blodanalyser',
  'Print page': 'Skriv ut sida',
  Message: 'Meddelande',
  Send: 'Skicka',
  'View available clinics': 'Visa tillgängliga kliniker ',
  Clinic: 'Klinik',
  'This analysis can only be performed in some locations.': 'Denna analys kan endast utföras på vissa platser.',
  Street: 'Gatuadress',
  'See the list': 'Se listan',
  'See package': 'Se paket',
  'I accept the': 'Jag accepterar',
  'terms and conditions': 'Personuppgiftspolicyn',
  'Register without bankid': 'Skapa konto utan BankID',
  'Update the address': 'Uppdatera adressen',
  'This analysis is available in all our locations.': 'Denna analys finns på alla våra orter.',
  'The analyses in this package are available in all our locations.': 'Analyserna i detta paket finns på alla våra orter.',
  'Some of the analyses in this package can only be performed in some locations.':
    'Vissa av analyserna i detta paket kan endast utföras på vissa platser.',
  'If you can’t remember your password, you can reset it.': 'Om du inte kommer ihåg ditt lösenord kan du återställa det.',
  'Reset Password Email sent': 'E-postmeddelande för återställning av lösenord har skickats',
  'Logout successful': 'Utloggningen lyckades',
  'Logout failed': 'Det gick inte att logga ut',
  'Item added to wishlist': 'Objekt har lagts till på önskelistan',
  'Item removed from wishlist': 'Objekt har tagits bort från önskelistan',
  Back: 'Tillbaka',
  'See graphs and analyses': 'Se grafer och analyser',
  'Questions and answers': 'Frågor och svar',
  'More information': 'Mer information',
  'Search for clinics': 'Sök på klinik',
  Low: 'Låg',
  High: 'Hög',
  Normal: 'Normal',
  Close: 'Stäng',
  'Awaiting labportallen confirmation...': 'Väntar på labportallen bekräftelse...',
  'Awaiting order confirmation...': 'Väntar på orderbekräftelse...',
  'I agree with the': 'Jag har läst och godkänner',
  'Back to Blodkoellen': 'Tillbaka till Blodkollen',
  Interpeted: 'Tolkad',
  'Waiting for your analyses to be interpreted by a specialist.': 'Väntar på att dina analyser ska tolkas av en specialist.',
  'For emergencies, sens SMS': 'Akut, skicka sms',
  'URGENT SMS on': 'AKUTA SMS på',
  'View all analyses': 'Visa alla analyser',
  analyses: 'analyser',
  Analyses: 'Analyser',
  'See comment': 'Se kommentar',
  'All our analyses': 'Alla analyser',
  Add: 'Lägg till',
  'Our blood samples': 'Våra blodprover',
  'Partial Results': 'Svar börja komma in',
  'Detailed Analysis': 'Detaljerad analys',
  'Reports explanation row 1':
    'Klicka på “Detaljerad analys” så visas dina svar, referensvärden och du kan läsa mer om vad just ditt resultat kan betyda. ',
  'Reports explanation row 2': 'Följ dina värden över tid och se hur din livsstil påverkar dina resultat.',
  'Open the BankID app on your mobile phone.': 'Öppna BankID-appen i mobilen.',
  'Tap the QR symbol in the app.': 'Tryck på QR-symbolen i appen.',
  'Open the camera and scan the QR code.': 'Öppna kameran och fotografera QR-koden.',
  'Legitimate yourself.': 'Legitimera dig själv.',
  'You currently have no reports': 'Du har för närvarande inga rapporter',
  'Message sent': 'Meddelande skickat',
  'Login with a one-time code': 'Logga in med engångskod',
  'Next step': 'Nästa steg',
  'Fill in your social security number': 'Fyll i ditt personnummer',
  'We have sent a verification code to your mobile and email': 'Vi har skickat en verifieringskod till din mobil och epost',
  'Enter the 6-digit verification code sent to your phone and email.':
    'Ange den 6-siffriga verifieringskoden som skickats till din telefon och epost. ',
  'Enter your one-time code': 'Fyll i din engångskod',
};
